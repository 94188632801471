<template>
  <div class="row mx-0 justify-content-center">
    <div class="col-11 col-md-10 mb-3 text-center" >
      Enter a name, description or reference you would to use as a personal identifier for this Recurring Donation setup (optional)
    </div>
    <div class="col-11 col-md-6">
      <TextField type="text" color="gold" placeholder="Reference" v-model="value" id="reference" />
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Review Donation Set Reference',
  components: {
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue'))
  },
  props: {
    modelValue: {
      type: String
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
